// ==========================================================================
// Footer styles
// ==========================================================================
.footer {
  background: $accent-color;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;

  // .container {
  //   height: 100%;
  // }
}

.footer-nav {
  height: 100%;
  display: block;
  margin: 0 auto;
}

.menu-list {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  
  &__item {
    color: $inverse-color;
    font-size: 16px;
    font-weight: 500;
  }

  &__link {
    color: $inverse-color;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }
}