$main-fz: 16px;
$primary-font: Montserrat, Arial, sans-serif;

$inverse-color: #fff;
$font-color: #141d27;
$font-light-color: #4D5965;
$primary-color: #FC343C;
$light-color: #d1dfee;
$primary-light-color: #ff4e4c;
$secondary-font-color: #3c4752;
$hidden-color: #677685;
$light-bg-color: #eef3f9;
$dark-color: #10161d;

$neutral-bg-color: #f9fbfc;

$accent-color: #5853c9;

$primary-font-color: #000;
$secondary-font-color: #2d2d2d;
$accent-font-color: $accent-color;




$z-index-0: 0;
$z-index-1: 1;
$z-index-2: 2;
$z-index-3: 3;
$z-index-4: 4;
$z-index-4: 5;

$layoutWidth: 1440px;
$textLayoutWidth: 940px;


/*Grid and media variables*/
$breakpoints: (
  // Mobile
    "320" : 320px,
    "360" : 360px,
    "375" : 375px,
    "400" : 400px,
    "480" : 480px,
    "560" : 560px,
    "640" : 640px,
    "768" : 768px,
    "800" : 800px,
    "992" : 992px,

  // Desktop
    "1024": 1024px,
    "1200": 1200px,
    "1280": 1280px,
    "1360": 1360px,
    "1366": 1366px,
    "1440": 1440px,
    "1536": 1536px,
    "1600": 1600px,
    "1680": 1680px,
    "1920": 1920px
);