.text-page {
  color: #2D2D2D;

  header {
    padding-top: 30px;
  }

  h1 {
    font-size: 56px;
    font-weight: 300;
    text-align: center;
    margin: 40px 0;
    color: #000;
  }

  a {
    color: #000;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 32px;
    font-weight: 400;
    color: #7A7E8F;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 28px;
    color: #000;
    margin-bottom: 10px;
  }

  h4 {
    color: #2D2D2D;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    color: #2D2D2D;

    a {
      color: $accent-color;
    }
  }

  strong {
    font-weight: 500;
  }

  ol {
    color: #2D2D2D;
    list-style: decimal;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  ul {
    color: #2D2D2D;
    margin-bottom: 20px;

    &.circle {
      list-style: circle;
      margin-left: 20px;
    }
  }

  ul, ol {
    li {
      line-height: 20px;
      margin-bottom: 12px;

      a {
        color: $accent-color;
      }
    }
  }
}

.legal {
  ul {
    li {
      list-style: circle;
    }
  }
}
