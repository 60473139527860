@media screen and (max-width: 1366px) {

  .section {
    &--padding {
      padding-left: 100px;
    }
  }

  .hero-section {

    &__presentation-block {
      width: 100%;
      max-width: 500px;
    }

    &__info-block {
      margin-left: 60px;        
    }
  }

  .feature-section {

    .container {
      justify-content: center;
      padding-left: 20px;
      padding-right: 20px;
    }

    .feature-list {
      margin-left: 100px;
    }
  }

  .recommendation-section {

    .recommendation-list {
      flex-direction: column;
      align-items: center;

      &__item:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .tips-section {

    .point-list {
      max-height: none;
      flex-direction: row;

      &__item {
        max-width: 560px;
      }

      &__item:nth-last-child(-n+2) {
        margin-left: 0;
        max-width: 560px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .hero-section {

    .container {
      flex-direction: column;
      align-items: center;
    }

    &__info-block {
      width: 100%;
      padding: 60px 20px 0 20px;
      text-align: center;
      margin: 0;

      .buttons-group {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .feature-section {

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .feature-list {
      margin-top: 40px;
      margin-left: 0;
      padding: 0 40px;
    }
  }

  .store-section {
    .container {
      box-sizing: border-box;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .buttons-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .mobile-app-button:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  .section {
    &--padding {
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .hero-section {
    &__title {
      font-size: 56px;
    }

    &__presentation-block {
      
      img {
        width: 280px;
        height: auto;
        align-self: center;
      }

      video {
        width: 210px;
        margin-top: -20px;
      }
    }
  }

  .recommendation-section {

    .recommendation-list {

      &__icon {
        margin: 0 0 20px 0;
      }

      &__item {
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
    }
  }

  .point-list, .toolbar-section .point-list {

    &__item {
      padding-top: 100px;
      padding-left: 0;
    }
  }

  .section {
    &__heading {
      font-size: 52px;
      line-height: 60px;
      text-align: center;

      &--small {
        font-size: 42px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .hero-section {
    &__title {
      font-size: 48px;
    }
  }
}