.nav {
  margin-left: 72px;
  position: relative;
  width: 100%;
  max-width: 1100px;
  transition: border-bottom 0.2s ease-in-out, color 0.1s ease-in-out, transform 0.3s ease-in-out;
}

.nav-list {
  height: 100%;
  display: flex;
  justify-content: space-between;

  &__item {
    font-family: $primary-font;
    color: $primary-font-color;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 4px solid transparent;
    transition: border-bottom 0.2s ease-in-out, color 0.1s ease-in-out;

    &--active, &:hover {
      border-bottom: 4px solid $accent-color;
    }

    &--external:hover {
      border-bottom: 4px solid transparent;
    }

    a {
      display: flex;
      align-items: center;
      color: inherit;
      height: 100%;
      margin-top: 3px;
    }
  }
}
  