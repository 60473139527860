// ==========================================================================
// Sections
// ==========================================================================
.section {
  position: relative;

  &--padding {
    padding-left: 225px;
  }

  &--overflow {
    overflow: hidden;
  }

  &__heading {
    font-size: 76px;
    color: $primary-font-color;
    font-weight: 400;

    &--small {
      font-size: 56px;
      line-height: 80px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 27px;
    color: #2d2d2d;

    strong {
      font-weight: bold;
    }
  }
}

.hero-section {

  &__title {
    font-family: $primary-font;
    font-weight: 300;
    color: $primary-font-color;
    font-size: 76px;
    margin-bottom: 20px;

    & .strong {
      font-weight: 700;
    }
  }

  &__sub-title {
    color: $accent-font-color;
    font-family: $primary-font;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 42px;
  }

  &__description {
    font-size: 16px;
    line-height: 26px;

    & + .hero-section__description {
      margin-top: 26px;
    }
  }

  &__info-block {
    max-width: 612px;
    padding-top: 174px;
    margin-left: 141px;
  }

  &__presentation-block {
    position: relative;
    margin-top: 90px;
    display: flex;
    justify-content: center;
    width: 650px;

    img {
      z-index: 2;
    }

    video {
      position: absolute;
      z-index: 2;
      top: 733px;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .container {
    padding-bottom: 65px;
  }
}

.feature-section {
  background: $accent-color;

  &__title {
    max-width: 370px;
    color: $inverse-color;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
  }

  .feature-list {
    margin-left: 190px;
  }

  .container {
    padding-top: 120px;
    padding-bottom: 128px;
    display: flex;
    padding-left: 245px;
  }
}

.store-section {
  background: #f1f7fc;

  &__title {
    font-weight: 500;
    font-size: 32px;
    color: #7a7e8f;
    text-align: center;
  }

  &__sub-title {
    color: #2d2d2d;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    margin: 0 auto;
    display: block;
    max-width: 580px;
    text-align: center;
    margin-top: 40px;
  }

  .buttons-group {
    margin: 0 auto;
    margin-top: 70px;
  }

  .container {
    padding-top: 148px;
    padding-bottom: 142px;
  }
}

.how-works-section {

  .container {
    padding-top: 135px;
  }

  .section__heading {
    margin-bottom: 63px;
  }

  .section__text {
    max-width: 840px;

    &:last-child{
      margin-top: 25px;
    }
  }
}

.instruction-section {

  .container {
    padding-top: 130px;
  }

  .section__heading {
    margin-bottom: 63px;
  }
}

.toolbar-section {

  .container {
    padding-top: 88px;
  }

  .point-list {
    margin-top: 67px;

    &__item {
      margin-bottom: 46px;
      padding-left: 100px;
    }
  }
}

.tips-section {

  .container {
    padding-top: 115px;
    padding-bottom: 165px;
  }

  .section__heading {
    max-width: 800px;
  }

  .point-list {
    list-style: none;
    margin-top: 60px;

    &__item:nth-last-child(-n+2) {
      max-width: 465px;
      margin-left: -150px;
    }
  }
}

.recommendation-list {
  justify-content: space-between;
  display: flex;

  &__item {
    align-items: center;
    display: flex;
    position: relative;
    font-size: 20px;
    line-height: 32px;
    color: #2d2d2d;
    font-weight: 500;
    max-width: 375px;

    &:first-child .recommendation-list__icon {
      background: url('/assets/img/icons/diplom-icon.svg') no-repeat center center #db5eaf;
    }

    &:nth-child(2) .recommendation-list__icon {
      background: url('/assets/img/icons/branches-icon.svg') no-repeat center center #744bc5;

    }

    &:nth-child(3) .recommendation-list__icon {
      background: url('/assets/img/icons/list-icon.svg') no-repeat center center #6c68d0;

    }
  }

  &__icon {
    margin-right: 20px;
    flex: 0 0 120px;
    background: $accent-color;
    display: block;
    position: relative;
    width: 120px;
    height: 120px;
  }
}

.recommendation-section {
  padding-top: 130px;
  padding-bottom: 160px;

  &__title {
    font-weight: 500;
    font-size: 32px;
    color: #7a7e8f;
    text-align: center;
    z-index: 2;
    position: relative;
  }

  .recommendation-list {
    max-width: 1216px;
    margin: 0 auto;
    margin-top: 78px;
    z-index: 2;
    position: relative;
  }
}

.feature-list {
  color: $inverse-color;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;

  &__item {
    position: relative;
    margin-bottom: 24px;

    &:before {
      content: '';
      width: 4px;
      height: 4px;
      background: #a6a9ed;
      position: absolute;
      left: -18px;
      top: 10px;
    }
  }
}

.point-list {
  list-style-position: inside;
  counter-reset: list;

  &--toolbar {
    list-style: none;

    .point-list__item {

      .accent {
        color: $accent-color;
        display: block;
        font-weight: 500;
        margin-bottom: 12px;
      }

      &:before {
        content: '';
      }

      &--repeat {
        &:before {
          background: url('/assets/img/icons/repeat-icon.svg') no-repeat 50% #6c68d0;
        }
      }

      &--share {
        &:before {
          background: url('/assets/img/icons/share-icon.svg') no-repeat 50% #6c68d0;
        }
      }

      &--library {
        &:before {
          background: url('/assets/img/icons/library-icon.svg') no-repeat 50% #6c68d0;
        }
      }

      &--info {
        &:before {
          background: url('/assets/img/icons/info-icon.svg') no-repeat 50% #6c68d0;
          background-size: 30px;
        }
      }
    }

  }

  &--in-columns {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 470px;

    .point-list__item {
      max-width: 450px;

      &:before {
        background: #58a0d6;
      }
    }
  }

  &__item {
    position: relative;
    padding-left: 105px;
    max-width: 760px;
    min-height: 80px;
    margin-bottom: 40px;

    &:before {
      font-family: $primary-font;
      color: $inverse-color;
      font-size: 50px;
      content: counter(list);
      counter-increment: list;
      position: absolute;
      width: 80px;
      height: 80px;
      top: 0;
      left: 0;
      background: #a4d1db;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
