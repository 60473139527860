
@media screen and (min-width: 651px) and (max-width: 1024px) {
  
  .nav {
    margin-right: 40px;
    margin-left: 40px;
  }

  .nav-list {
    flex-wrap: wrap;

    &__item:nth-child(-n+3){
      margin-left: 20px;
      margin-right: 20px;
    }

    &__item:nth-last-child(-n+2){
      flex-basis: 50%;
      margin-top: 10px;
    }

    &__item  {
      border-bottom-width: 2px;
      padding-bottom: 10px;

      &:hover {
        border-bottom-width: 2px;
      }

      a {
        justify-content: center;
        font-size: 16px;
      }
    }
  }

}

@media screen and (max-width: 650px) {
  .nav {
    margin: 0 auto;
    margin-top: 32px;
  }

  .nav-list {
    flex-direction: column;
    align-items: center;
    
    &__item {
      padding-bottom: 5px;
      border-bottom-width: 2px;

      &:hover {
        border-bottom-width: 2px;
      }
    }

    &__item:not(:first-child) {
      margin-top: 20px;
    }
  }
}