// ==========================================================================
// Header styles
// ==========================================================================
.header {
  display: flex;
  // height: 48px;
  padding: 0 20px;
  padding-top: 20px;
  box-sizing: border-box;

  &__logo {
    width: 48px;
    height: 48px;
  }
}
