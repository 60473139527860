.buttons-group {
    display: flex;
    width: 100%;
    max-width: 574px;
    justify-content: space-between;
    margin-top: 64px;
}

.mobile-app-button {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 270px;
    height: 80px;
    background: $accent-color;
    border: none;
    padding: 0;

    &__icon-holder {
        width: 80px;
        height: 80px;
        flex: 0 0 80px;
        background: #5866cb;
        display: flex;
        align-items: center;
        justify-content: center;

        &--other-bg {
            background: #7355c7;
        }
    }

    &__text {
        color: $inverse-color;
        font-family: $primary-font;
        font-weight: 300;
        font-size: 14px;
        padding-left: 20px;
        text-align: left;
        display: block;

        display: flex;
        flex-direction: column;
        justify-content: center;
        
        strong {
            font-weight: 600;
            font-size: 22px;
        }
    }
}

.icon-app-store {
    background-image: url('/assets/img/icons/app-store-icon.svg');
    width: 37px;
    height: 37px;
}

.icon-google-play {
    background-image: url('/assets/img/icons/google-play-market-icon.svg');
    width: 34px;
    height: 37px;
}