html {
  font-size: $main-fz;
  font-family: $primary-font;
}

@media screen and (max-width: 1200px) {
  $main-fz: 15px;
  html {
    font-size: $main-fz;
  }
}

@media screen and (max-width: 992px) {
  $main-fz: 14px;
  html {
    font-size: $main-fz;
  }
}

@media screen and (max-width: 768px) {
  $main-fz: 13px;
  html {
    font-size: $main-fz;
  }
}

@media screen and (max-width: 576px) {
  $main-fz: 12px;
  html {
    font-size: $main-fz;
  }
}

html, body {
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

a {
  &:link {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }
}

table th, table td {
  vertical-align: middle;
}

button {
  cursor: pointer;
}

.container {
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(#{$layoutWidth} + 40px);
  padding-right: 20px;
  padding-left: 20px;

  &--flex {
    display: flex;
  }

  &--text {
    max-width: $textLayoutWidth;
  }
}
