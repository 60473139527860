@media screen and (max-width: 1366px) {

  .decoration {

    &--recomendation-left {
      display: block;
    }

    &--recomendation-right {
      display: block;
    }
  }
}

@media screen and (max-width: 1600px) {
  .decoration {

    &--how-works-right {
      left: 800px;
    }

    &--instruction-right {
      left: 1120px;
    }

    &--toolbar-right {
      left: 865px;
    }
  }
}