.decoration {
  position: absolute;
  z-index: 1;

  &--hero-left {
    left: -120px;
    bottom: 0;
    background: url('/assets/img/background/main-page/hero-left.svg') no-repeat;
    width: 100%;
    height: 120px;
  }

  &--hero-right {
    right: 0;
    top: 52px;
    background: url('/assets/img/background/main-page/hero-right.svg') no-repeat;
    width: 240px;
    height: 100%;
  }

  &--features-left {
    left: 0;
    bottom: 0;
    background-image: url('/assets/img/background/main-page/features-left.svg');
    width: 360px;
    height: 240px;
  }

  &--features-right {
    right: 0;
    bottom: 0;
    background: url('/assets/img/background/main-page/features-right.svg') no-repeat;
    width: 240px;
    height: 100%;
  }

  &--how-works-right {
    right: 160px;
    top: 70px;
    background: url('/assets/img/background/instruction-page/section-1-right.svg') no-repeat;
    width: 480px;
    height: 100%;
  }

  &--how-works-left {
    left: 0;
    top: 0;
    background-image: url('/assets/img/background/instruction-page/section-1-left.svg');
    width: 120px;
    height: 120px;
  }

  &--instruction-right {
    top: 200px;
    right: 180px;
    opacity: 0.1;
  }

  &--instruction-left {
    top: 320px;
    left: -230px;
  }

  &--recomendation-right {
    display: none;
    right: 0;
    top: -50px;
    background: url('/assets/img/background/recomendation-right-mobile.svg') no-repeat;
    width: 240px;
    height: 720px;
  }

  &--recomendation-left {
    display: none;
    left: 0;
    top: 220px;
    background-image: url('/assets/img/background/recomendation-left-mobile.svg');
    width: 240px;
    height: 240px;
  }

  &--toolbar-left {
    left: -230px;
    bottom: -170px;
    background: url('/assets/img/background/instruction-page/section-2-left.svg') no-repeat;
    width: 120px;
    height: 240px;
  }

  &--toolbar-right {
    right: 70px;
    top: 256px;
    background: url('/assets/img/background/instruction-page/section-2-right.svg') no-repeat;
    width: 480px;
    height: 100%;
  }

  &--footer-right {
    right: 0;
    top: -120px;
    background-image: url('/assets/img/background/footer-right-bg.svg');
    width: 240px;
    height: 360px;
  }

  &--footer-left {
    left: 0;
    bottom: -120px;
    background-image: url('/assets/img/background/footer-left.svg');
    width: 120px;
    height: 240px;
  }
}
