@media screen and (max-width: 1024px) {
  .decoration {
    display: none;
  }

  .section {
    overflow: hidden;
  }

  .container {
    box-sizing: border-box;
  }
}