@media screen and (max-width: 1366px) {
  
  .header {
    padding-left: 20px;
    padding-right: 20px;
  }

}

@media screen and (max-width: 650px) {
  
  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}