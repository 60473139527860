// // ==========================================================================
// // Main file to connect all structural pieces
// // ==========================================================================


/* Libs */
@import "./_generic/reset";
// @import "./_generic/normalize";

/* Settings */
@import "./_settings/variables";

/* Tools */
@import "./_tools/mixins";

/* Base */
@import "./_general/base";
@import "./_general/sprite";
@import "./_general/buttons";


/* Components */
@import "./_components/header";
@import "./_components/nav";
@import "./_components/sections";
@import "./_components/footer";
@import "./_components/decorations";
@import "./_components/text-page";

@import "./_components/ui/buttons";


/* Responsive */
@import "./_components/responsive/base";
@import "./_components/responsive/nav";
@import "./_components/responsive/header";
@import "./_components/responsive/sections";
@import "./_components/responsive/footer";
@import "./_components/responsive/decorations";

