@media screen and (max-width: 1366px) {
  .footer {
    .menu-list {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    min-height: 120px;
    height: auto;
    padding-top: 20px;

    .menu-list {
      justify-content: center;
      flex-wrap: wrap;

      &__item {
        margin-bottom: 15px;
      }

      &__item:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}
